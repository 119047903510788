import { Subscription, interval } from 'rxjs';

export class CountDownHelper {
  public static startCountdown(
    fromSeconds: number | undefined,
    onCountdownTick: (secondsRemaining: number) => void,
    onCountdownComplete?: () => void,
  ): Subscription {
    const targetDate = new Date();
    if (!fromSeconds) {
      fromSeconds = 30;
    }
    const DELAY_TIME = 2; // SECONDs
    targetDate.setSeconds(targetDate.getSeconds() + fromSeconds + DELAY_TIME);

    let secondsRemaining: number;
    const subscription = interval(1000).subscribe(() => {
      const now = new Date();
      secondsRemaining = Math.floor(
        (targetDate.getTime() - now.getTime()) / 1000,
      );

      if (secondsRemaining >= 0) {
        onCountdownTick(secondsRemaining);
      } else {
        // Countdown completed
        subscription.unsubscribe();

        if (onCountdownComplete) {
          onCountdownComplete();
        }
      }
    });

    return subscription;
  }
}
