import {
  IAccountSetting,
  LinkSocialNetwork,
} from '@features/auth/core/domain/account/account-setting.interface';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { URI_APIs } from '@features/auth/const';
import { HttpClient } from '@angular/common/http';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { ChangePasswordError } from './model/change-password-error.model';
import { UserInfoModel } from './model/user-info.model';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';
import { UserInfoStorageService } from '../login/user-info-storage.service';

@Injectable()
export class AccountSettingService
  implements IAccountSetting, LinkSocialNetwork
{
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);
  private readonly userInfoStorageService = inject(UserInfoStorageService);

  changeEmailAddress(emailAddress: string): Observable<any> {
    const requestBody = { email: emailAddress };
    return this.httpClient.post(
      this.conf.buildURL(URI_APIs.PROFILE_UPDATE_EMAIL),
      requestBody
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.PROFILE_CHANGE_PASSWORD);
    const requestBody = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    return this.httpClient
      .post(url, requestBody)
      .pipe(
        catchError((err) => throwError(() => ChangePasswordError.handle(err)))
      );
  }

  updateAvatar(avatar: {
    server_file_name: string;
    file_name: string;
    file_hash: string;
  }): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.PROFILE_UPDATE);
    const requestBody = {
      avatar: avatar,
    };
    return this.httpClient.post(url, requestBody).pipe(
      tap((res) => {
        this.setUserInfo({ avatar: avatar });
      })
    );
  }

  saveUserInfo(full_name: string): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.PROFILE_UPDATE);
    const requestBody = {
      ho_ten: full_name,
    };
    return this.httpClient.post(url, requestBody).pipe(
      tap((res) => {
        this.setUserInfo({ ho_ten: full_name }, false);
      })
    );
  }

  getAccountInfo(): Observable<any> {
    return this.httpClient
      .get<UserInfoModel>(this.conf.buildURL(URI_APIs.PROFILE_GET))
      .pipe();
  }

  setUserInfo(
    data: { avatar?: any; ho_ten?: any },
    refresh: boolean = true
  ): Observable<any> {
    const userInfo = StorageHelper.getUserInfo();
    if (userInfo) {
      if (data.avatar) {
        userInfo['avatar'] = data.avatar;
      }
      if (data.ho_ten) {
        userInfo['ho_ten'] = data.ho_ten;
      }
    }
    StorageHelper.setUserInfo(userInfo);

    if (refresh) {
      this.userInfoStorageService.onefreshData();
    }
    return of(true);
  }

  upload = (blob: Blob) => {
    const originalFileName = 'avatar.png';
    const serverFolderName = '';
    const fileExtension = originalFileName.split('.').pop();

    const formData = new FormData();
    formData.append('type', 'image');
    formData.append('folder_type', serverFolderName);
    formData.append('files', blob, originalFileName);
    formData.append('ext', fileExtension ?? '');

    return this.httpClient
      .post(this.conf.buildFileV2URL('/upload'), formData)
      .pipe(map((e: any) => e['files'][0]));
  };


  linkGoogle(token: string): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.LINK_GOOGLE_ACCOUNT);
    return this.httpClient.post(url, { access_token: token });
  }
  // TODO: Need implement
  unlinkGoogle(): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.UN_LINK__GOOGLE_ACCOUNT);
    return this.httpClient.post(url, {});
  }
}
