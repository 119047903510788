import { ErrorBase } from '@core/models/error.model';

export enum ChangePasswordErrorCode {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  OLD_PASSWORD_NOT_MATCH = 'OLD_PASSWORD_NOT_MATCH',
}

export class OwnerChangePasswordError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class ChangePasswordError {
  public static handle(error: ErrorBase) {
    throw new OwnerChangePasswordError(error.desc?.message);
  }
}
